import {ActivityItemType} from '@toolify/server/src/models/mongoose/ActivityItemModel/enum/ActivityItemType'
import {
  IActivityItemInterfacesMap,
} from '@toolify/server/src/services/ActivityItemService/types/IActivityItemInterfacesMap'
import {ResourceType} from '@toolify/server/src/models/mongoose/PermissionModel/enum/ResourceType'
import {
  IBaseResourcePathItem,
} from '@toolify/server/src/services/ResourceService/types/resourcePath/IBaseResourcePathItem'
import {onBeforeUnmount, onMounted, watch} from 'vue'
import {useSocketStore} from '@toolify/client/src/stores/SocketStore/useSocketStore'
import {SocketEventPrefixType} from '@toolify/server/src/services/SocketService/enum/SocketEventPrefixType'
import {IResponseEntity} from '@toolify/server/src/services/EntityService/types/IResponseEntity'
import {
  IActivityItemSocketPayload,
} from '@toolify/server/src/facades/ActivityItemFacade/types/IActivityItemSocketPayload'
import {
  IResponseResourcePath,
} from '@toolify/server/src/services/ResourceService/types/resourcePath/IResponseResourcePath'
import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IResponseResourcePathItem,
} from '@toolify/server/src/services/ResourceService/types/resourcePath/IResponseResourcePathItem'
import {mapAuthorResponseEntity} from '@toolify/client/src/modules/api/singletons/mapAuthorResponseEntity'
import {mapResponseResourcePath} from '@toolify/client/src/modules/api/singletons/mapResponseResourcePath'

interface fnPayload<T extends ActivityItemType|null> {
  activityItemType: ActivityItemType
  payload: IToJson<IActivityItemInterfacesMap[T]['payload']>,
  transientPayload: IToJson<IActivityItemInterfacesMap[T]['transientPayload']>,
  resourcePath: IResponseResourcePath,
  targetResource: IBaseResourcePathItem,
  resourcePathItemMap: Map<ResourceType, IBaseResourcePathItem>
  authorEntity: IResponseEntity
  id: string
  canBeReverted: boolean
  hasBeenReverted: boolean
  restoredFromActivityItemId?: string
}

export function useActivityItemSocketListener<T extends ActivityItemType|null>(
  activityItemType: T,
  fn: (payload: fnPayload<T>) => void,
  resourcePathFilter?: {
    resourceType: ResourceType|(() => ResourceType),
    resourceId: () => string
  }|null,
  options?: {
    useLifecycleHooks?: boolean
    lifecycleFn?: () => boolean
  },
) {
  const socketStore = useSocketStore()

  const fnOverrideMultiple = (
    receivedActivityItemType: ActivityItemType,
    data: IActivityItemSocketPayload<T>[],
  ) => {
    for(const item of data) {
      const {
        resourcePath,
        authorEntity,
        id,
        canBeReverted,
        hasBeenReverted,
        restoredFromActivityItemId,
        transientPayload,
        payload,
      } = item
      if (resourcePathFilter) {
        const executedResourceType = typeof resourcePathFilter.resourceType === 'function' ? resourcePathFilter.resourceType() : resourcePathFilter.resourceType
        const executedResourceId = resourcePathFilter.resourceId()
        const foundResource = resourcePath.find(item => {
          return item.resourceType === executedResourceType
            && item.resourceId === executedResourceId
        })
        if (!foundResource) {
          return
        }
      }
      const targetResource = resourcePath[resourcePath.length - 1]
      const resourcePathItemMap: Map<ResourceType, IBaseResourcePathItem> = new Map()
      for (const item of resourcePath) {
        resourcePathItemMap.set(item.resourceType as ResourceType, item as IResponseResourcePathItem<ResourceType>)
      }
      fn({
        activityItemType: receivedActivityItemType,
        payload,
        transientPayload,
        resourcePath: mapResponseResourcePath(resourcePath),
        targetResource,
        resourcePathItemMap,
        authorEntity: mapAuthorResponseEntity(authorEntity),
        id,
        canBeReverted,
        hasBeenReverted,
        restoredFromActivityItemId,
      })
    }
  }
  
  const fnOnAny = (eventName: string, payload: unknown) => {
    let firstPart = null
    let multiple = null
    if(eventName.startsWith(SocketEventPrefixType.Activities)) {
      firstPart = `${SocketEventPrefixType.Activities}:`
      multiple = true
    } else if(eventName.startsWith(SocketEventPrefixType.Activity)) {
      firstPart = `${SocketEventPrefixType.Activity}:`
      multiple = false
    } else {
      return
    }
    const eventNameArray = eventName.split(firstPart)
    const receivedActivityItemType = eventNameArray[1] as ActivityItemType
    if(activityItemType && receivedActivityItemType !== activityItemType) {
      return
    }
    const activityItemPayload = payload
    if(multiple) {
      fnOverrideMultiple(receivedActivityItemType, activityItemPayload as IActivityItemSocketPayload<T>[])
      return
    }
    fnOverrideMultiple(receivedActivityItemType, [activityItemPayload as IActivityItemSocketPayload<T>])
  }

  function start() {
    socketStore.onAny(fnOnAny)
  }

  function stop() {
    socketStore.offAny(fnOnAny)
  }

  if(options?.useLifecycleHooks !== false && !options?.lifecycleFn) {
    onMounted(() => {
      start()
    })

    onBeforeUnmount(() => {
      stop()
    })
  }

  watch(() => {
    return options?.lifecycleFn?.()
  }, (value, oldValue) => {
    if(!options?.lifecycleFn) {
      return
    }
    if(value === oldValue) {
      return
    }
    if(value) {
      return start()
    }
    stop()
  }, {
    immediate: true,
  })

  return {
    start,
    stop,
  }
}
