<script setup lang="ts">
import {$computed, $ref} from 'vue/macros'
import {nextTick, onMounted} from 'vue'
import {PropertyStrategyThemeType} from '@toolify/client/src/modules/tableItems/enum/PropertyStrategyThemeType'

interface Props {
  type?: 'text'|'number'|'password'|'datetime-local'
  focusOnMounted?: boolean
  modelValue?: string|number
  background?: 'lowest'|'low'|'normal'|'high'
  theme?: PropertyStrategyThemeType
  placeholder?: string
  showOnClick?: boolean
  textAlignment?: 'left'|'center'|'right'
  isEditing?: boolean
  fullWidth?: boolean
  iconClass?: string
  height?: number
  width?: number
}

interface Emits {
  (e: 'update:modelValue', value: string|number)
  (e: 'update:isEditing', value: boolean)
  (e: 'change', value: string|number)
  (e: 'blur', value: string|number)
  (e: 'enter')
  (e: 'tab')
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  focusOnMounted: false,
  modelValue: '',
  theme: PropertyStrategyThemeType.Primary,
  placeholder: '',
  showOnClick: false,
  background: 'low',
  textAlignment: 'left',
  fullWidth: false,
  iconClass: '',
  height: null,
  width: null,
})

const emit = defineEmits<Emits>()

const onInput = (event) => {
  let targetValue: string|number
  if(props.type === 'number') {
    targetValue = Number(event.target.value)
  } else {
    targetValue = String(event.target.value)
  }
  emit('update:modelValue', targetValue)
}

const input = $ref(null)

const value = $computed({
  get() {
    if(props.type === 'number') {
      return props.modelValue
    }
    if(!props.modelValue) {
      return ''
    }
    return String(props.modelValue)
  },
  set(value) {
    if(props.type === 'number') {
      return emit('change', Number(value))
    }
    emit('change', String(value))
  },
})

// const keyboardShortcuts = useKeyboardShortcuts([])
onMounted(async () => {
  // keyboardShortcuts.unregisterShortcuts()
  if(props.focusOnMounted) {
    await nextTick(() => input?.focus())
  }
})
const focus = () => {
  input?.focus()
}

const isEditing = $computed({
  get() {
    return props.isEditing
  },
  set(value) {
    emit('update:isEditing', value)
  },
})

defineExpose({
  focus,
})

const additionalStyles = $computed(() => {
  return {
    'text-align': props.textAlignment,
    'width': props.fullWidth ? '100%' : props.width ? `${props.width}px` : 'auto',
    'height': props.height ? `${props.height}px` : '36px',
  }
})

</script>

<template>
  <input
    :class="['input', `theme-${props.theme}`, `background-${props.background}`]"
    ref="input"
    :type="props.type"
    :placeholder="props.placeholder"
    @input="onInput"
    :value="value"
    v-bind="$attrs"
    @focus="isEditing = true"
    @change="emit('change', ($event.target as HTMLInputElement).value)"
    @keydown.enter="emit('enter')"
    :style="additionalStyles"
    @blur="emit('blur', ($event.target as HTMLInputElement).value) ; isEditing = false"
  />
</template>

<style scoped lang="scss">
  @use '../../../../styles/mixins' as mixins;
  @use '../../../../styles/variables' as variables;

  .input {
    @include mixins.inputStyle;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
    color: map-get(variables.$colors, content-normal) !important;
    -webkit-text-fill-color: map-get(variables.$colors, content-normal) !important;
    caret-color: map-get(variables.$colors, content-normal) !important;
  }
</style>
